import { useRouter } from "next/router";
import { useEffect, useState } from "react";
import { getValueOrDefault } from "~/lib/shared/getValueOrDefault";
import { valueIsEnum } from "~/lib/shared/typeUtilities";
import { SearchyOrder } from "~/types/searchy/SearchyOrder";
import { OptionData } from "~/ui-library/components/Select";

const sortOptions: OptionData[] = [
  {
    label: "Most Recent",
    id: SearchyOrder.CREATED_AT_DESC,
  },
  {
    label: "Least Recent",
    id: SearchyOrder.CREATED_AT_ASC,
  },
];

type SupportedSearchParams = {
  content_type?: string | number | null;
  industry?: string | number | null;
  sort?: string | number | null;
  /** tag ID to filter on */
  topic?: string | number | null;
};

type UseGenericSearchOptions = {
  categoryOptions: OptionData[];
  contentTypeOptions: OptionData[];
  topicOptions: OptionData[];
  onFilterUpdate: (filterString: string) => void;
};

const getQueryParams = (
  newValues: SupportedSearchParams,
  currentValues: SupportedSearchParams
): string => {
  const {
    content_type: contentType,
    industry,
    sort,
    topic,
  } = {
    ...currentValues,
    ...newValues,
  };

  const params = new URLSearchParams();

  if (contentType) {
    params.set("content_type", contentType.toString());
  }

  if (industry) {
    params.set("industry", industry.toString());
  }

  if (sort) {
    params.set("sort", sort.toString());
  }

  if (topic) {
    params.set("topic", topic.toString());
  }

  return params.toString();
};

export const useGenericSearch = ({
  categoryOptions,
  contentTypeOptions,
  topicOptions,
  onFilterUpdate,
}: UseGenericSearchOptions) => {
  const router = useRouter();
  const { query } = router;

  const [enableSearch, setEnableSearch] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState<OptionData | null>(
    null
  );
  const [selectedContentType, setSelectedContentType] =
    useState<OptionData | null>(null);
  const [selectedSort, setSelectedSort] = useState<OptionData | null>(null);
  const [selectedTopic, setSelectedTopic] = useState<OptionData | null>(null);

  // initialize the state based on query params
  useEffect(() => {
    const search = new URLSearchParams(window.location.search);
    const category = search.get("industry");
    const contentType = search.get("content_type");
    const sort = search.get("sort") as SearchyOrder;
    const topic = search.get("topic");

    if (!enableSearch) {
      if (valueIsEnum(sort, SearchyOrder)) {
        setSelectedSort(
          getValueOrDefault(
            sortOptions.find(({ id }) => id === sort),
            null
          )
        );
      } else {
        setSelectedSort(sortOptions[0]);
      }

      if (contentType) {
        setSelectedContentType(
          getValueOrDefault(
            contentTypeOptions.find(({ id }) => id === contentType),
            null
          )
        );
      }

      if (category) {
        setSelectedCategory(
          getValueOrDefault(
            categoryOptions.find(({ id }) => id === category),
            null
          )
        );
      }

      if (topic) {
        setSelectedTopic(
          getValueOrDefault(
            topicOptions.find(({ id }) => id.toString() === topic),
            null
          )
        );
      }

      setEnableSearch(true);
    }
  }, [enableSearch, categoryOptions, topicOptions, contentTypeOptions]);

  const onCategorySelect = (item: OptionData | null) => {
    setSelectedCategory(item);
    setSelectedTopic(null);
    const queryString = getQueryParams(
      { industry: item?.id, topic: undefined },
      query
    );
    onFilterUpdate(queryString);
  };

  const onContentTypeSelect = (item: OptionData | null) => {
    setSelectedContentType(item);
    setSelectedCategory(null);
    setSelectedTopic(null);
    const queryString = getQueryParams(
      { content_type: item?.id, industry: undefined, topic: undefined },
      query
    );

    onFilterUpdate(queryString);
  };
  const onSortSelect = (item: OptionData) => {
    setSelectedSort(item);
    const queryString = getQueryParams({ sort: item.id }, query);
    onFilterUpdate(queryString);
  };

  const onTopicSelect = (item: OptionData | null) => {
    setSelectedTopic(item);
    setSelectedCategory(null);
    const queryString = getQueryParams(
      { topic: item?.id, industry: undefined },
      query
    );
    onFilterUpdate(queryString);
  };

  return {
    enableSearch,
    selectedCategory,
    selectedContentType,
    selectedSort,
    selectedTopic,
    onCategorySelect,
    onContentTypeSelect,
    onSortSelect,
    onTopicSelect,
  };
};
